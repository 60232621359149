/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'

import domready from 'domready'
import formSubmit from './modules/form-submit'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import initModal from './modules/modal'
import initToggle from './modules/toggle-container'
import initMaps from './modules/map'
import fetchPosts from './modules/fetch-facebook'
import Swiper from 'swiper'
import baguetteBox from 'baguettebox.js'

/**
 * Ab geht die Reise
 */
domready(() => {
  formSubmit()
  initToggleSubMenu()
  initModal()
  initToggle()
  initMaps()
  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })

  // eslint-disable-next-line
  new Swiper('#headerbild-container', {
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: false
  })

  // eslint-disable-next-line
  new Swiper('#slider-container', {
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    speed: 800
  })
  baguetteBox.run('.lightbox')

  fetchPosts({
    api: 'v2.8',
    pageID: 'dachdeckerweimert',
    endpoint: 'posts',
    accessToken: '318302465253002|5db7aa6a1e9e2a2a2f62779e53e6a85f',
    fields: 'full_picture,message,link,name,description,created_time,type,likes',
    limit: '10'
  }, function (err, res, statusCode) {
    if (err) {
      console.log(err)
    } else {
      console.log(res)
    }
  })
})
