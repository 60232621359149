/**
 * Created by Tom on 10.11.2016.
 */
/* global Element */
import selectAll from './select-all'

function toggleContainer (elem, tabs) {
  let aktID = elem.getAttribute('data-id')
  let tabContent = selectAll('div.toggle-container-sub')
  let tabContentSub = selectAll('div.toggle-container-sub-ref')
  let toggleArrow = selectAll('div.toggle-arrow')
  let tab = selectAll('.toggle-link')
  let setTab = false
  if (!document.getElementById(aktID)) {
    return false
  }
  if (document.getElementById(aktID).classList.contains('hide')) {
    setTab = true
  }
  if (tabContent) {
    for (let y = tabContent.length - 1; y >= 0; y--) {
      tabContent[ y ].classList.remove('show')
      tabContent[ y ].classList.add('hide')
    }
  }
  if (tabContentSub) {
    for (let y = tabContentSub.length - 1; y >= 0; y--) {
      tabContentSub[ y ].classList.remove('show')
      tabContentSub[ y ].classList.add('hide')
    }
  }

  for (let z = tab.length - 1; z >= 0; z--) {
    tab[ z ].classList.remove('active')
  }
  for (let z = toggleArrow.length - 1; z >= 0; z--) {
    tab[ z ].classList.remove('active')
  }
  if (setTab) {
    document.getElementById(aktID).classList.remove('hide')
    document.getElementById(aktID).classList.add('show')
    if (document.getElementById(aktID + '-arrow')) {
      document.getElementById(aktID + '-arrow').classList.add('active')
    }
    elem.classList.add('active')
    let firstChild = document.getElementById(aktID).getElementsByTagName('p')[ 0 ]
    if (firstChild) {
      firstChild.classList.add('active')
      if (firstChild.getAttribute('data-id')) {
        document.getElementById(firstChild.getAttribute('data-id')).classList.remove('hide')
        document.getElementById(firstChild.getAttribute('data-id')).classList.add('show')
      }
    }
  } else {
    document.getElementById(aktID).classList.remove('show')
    document.getElementById(aktID).classList.add('hide')
    if (document.getElementById(aktID + '-arrow')) {
      document.getElementById(aktID + '-arrow').classList.remove('active')
    }
    elem.classList.add('active')
  }
}
function getParents (elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s)
        let i = matches.length
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1
      }
  }

  // Setup parents array
  let parents = []

  // Get matching parent elements
  for (; elem && elem !== document; elem = elem.parentNode) {
    // Add matching parents to array
    if (selector) {
      if (elem.matches(selector)) {
        parents.push(elem)
      }
    } else {
      parents.push(elem)
    }
  }
  return parents
}

export default function initToggle () {
  let toggleLink = selectAll('.toggle-link')
  if (toggleLink !== null) {
    for (let i = toggleLink.length - 1; i >= 0; i--) {
      toggleLink[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        toggleContainer(this, toggleLink)
        return false
      })
    }
  }
  let hash = window.location.hash
  if (hash) {
    hash = hash.replace('#', '')
    let e = document.getElementById(hash)
    let parents = getParents(e, '.toggle-container')
    console.log(parents)
    if (parents.length > 0) {
      for (let y = 0; y < parents.length; y++) {
        let link = document.querySelectorAll('[data-id="' + parents[ y ].id + '"]')
        toggleContainer(link[0], toggleLink)
      }
    }
  }
}
