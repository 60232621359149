/**
 * Created by Tom on 10.11.2016.
 */
/* global google */
import selectAll from './select-all'

function mapInitialize (elem) {
  var lat = elem.getAttribute('data-lat')
  var lon = elem.getAttribute('data-lon')
  var contentString = elem.getAttribute('data-content')
  var zoom = 16
  var map

  var myOptions = {
    zoom: zoom,
    center: new google.maps.LatLng(lat, lon),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: false,
    navigationControl: true,
    navigationControlOptions: { style: google.maps.NavigationControlStyle.SMALL },
    mapTypeControl: false,
    scaleControl: true,
    scrollwheel: false
  }

  map = new google.maps.Map(elem, myOptions)
  var markerGeo = new google.maps.LatLng(lat, lon)
  var marker = new google.maps.Marker({
    position: markerGeo,
    map: map
  })
  var infowindow = new google.maps.InfoWindow({
    content: contentString
  })
  infowindow.open(map, marker)

  google.maps.event.addListener(marker, 'click', function () {
    infowindow.open(map, marker)
  })
}

export default function initMaps () {
  let maps = selectAll('div.maps-container')
  if (maps !== null) {
    for (var i = maps.length - 1; i >= 0; i--) {
      google.maps.event.addDomListener(window, 'load', mapInitialize(maps[ i ]))
    }
  }
}
