/* global FB */
import makeAjax from './ajax-request'

export default function getPosts (page) {
  FB.api(
    '/' + page + '/posts',
    'GET',
    {
      'limit': '20',
      'fields': 'full_picture,place,message,link,name,description,created_time,type,likes',
      'access_token': '943594269115876|25c7d1e0fb3061d77d1f6cb8d0145252'
    },
    function (response) {
      console.log(response)
      makeAjax({
        modul: 'savePosts',
        modulPfad: 'api/facebook/',
        response: response
      }, (err, res, body) => {
        console.log(err, res, body)
      })
    }
  )
}
